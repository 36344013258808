export interface IEnvironment {
  name: string;
  urlMatches: string[];
  configurationService: string;
  apiEndpointPrefix: string;
  authority: string;
  clientId: string;
  development?: boolean;
}

export const environments: IEnvironment[] = [
  // PRODUCTION
  {
    name: 'CSR Sell-In',
    urlMatches: ['/csr-portal.'],
    configurationService: 'https://asterix-wapp-prod-san.azurewebsites.net/V4/Configuration',
    apiEndpointPrefix: 'https://asterix-wapp-prod-san.azurewebsites.net/',
    authority: 'https://login.microsoftonline.com/touchsides.com',
    clientId: '26985b3f-5a43-4bc6-9d59-6b4ba317ce47',
  },
  {
    name: 'Production',
    urlMatches: ['/portal.holaclub.co.za','/sizamina.holaclub.co.za'],
    configurationService: 'https://asterix-wapp-prod-san.azurewebsites.net/V4/Configuration',
    apiEndpointPrefix: 'https://asterix-wapp-prod-san.azurewebsites.net/',
    authority: 'https://login.microsoftonline.com/touchsides.com',
    clientId: '26985b3f-5a43-4bc6-9d59-6b4ba317ce47',
  },  
  
  // STAGING
  {
    name: 'Staging Portal',
    urlMatches: ['portal.staging', '/stagingportal.asterixpos.co.za', '/portal-wapp-staging-san.azurewebsites.net', '/csr-portal-wapp-staging-san.azurewebsites.net'],
    configurationService: 'https://aspgateway-wapp-staging-san.azurewebsites.net/V4/Configuration',
    apiEndpointPrefix: 'https://aspgateway-wapp-staging-san.azurewebsites.net/',
    // configurationService: 'https://aspgateway-stg.asterixdevice.co.za/V4/Configuration',
    // apiEndpointPrefix: 'https://aspgateway-stg.asterixdevice.co.za/',
    authority: 'https://login.microsoftonline.com/touchsides.com',
    clientId: '26985b3f-5a43-4bc6-9d59-6b4ba317ce47',
  },

  // BETA
  {
    name: 'Beta Portal',
    urlMatches: ['portal.beta', '/betaportal.asterixpos.co.za', '/beta-portal-wapp-dev-san.azurewebsites.net', '/beta-csr-portal-wapp-dev-san.azurewebsites.net'],
    configurationService: 'https://beta-aspgateway-wapp-dev-san.azurewebsites.net/V4/Configuration',
    apiEndpointPrefix: 'https://beta-aspgateway-wapp-dev-san.azurewebsites.net/',
    // configurationService: 'https://aspgateway-bt.asterixdevice.co.za/V4/Configuration',
    // apiEndpointPrefix: 'https://aspgateway-bt.asterixdevice.co.za/',
    authority: 'https://login.microsoftonline.com/touchsides.com',
    clientId: '26985b3f-5a43-4bc6-9d59-6b4ba317ce47',
  },

  // LOCAL
  {
    name: 'Hoster Platform',
    urlMatches: ['localhost:5000'],
    configurationService: 'http://localhost:5005/gateway/V4/Configuration',
    apiEndpointPrefix: 'http://localhost:5005/gateway/',
    authority: 'https://login.microsoftonline.com/touchsides.com',
    clientId: '26985b3f-5a43-4bc6-9d59-6b4ba317ce47',
  },
  {
    name: 'Local',
    urlMatches: ['localhost:3000'],
    configurationService: 'https://localhost:44301/V4/Configuration',
    apiEndpointPrefix: 'https://localhost:44301/',
    authority: 'https://login.microsoftonline.com/touchsides.com',
    clientId: '26985b3f-5a43-4bc6-9d59-6b4ba317ce47',
    development: true,
  },
  {
    name: 'Container',
    urlMatches: ['localhost:3001'],
    configurationService: 'http://localhost:52000/V4/Configuration',
    apiEndpointPrefix: 'http://localhost:52000/',
    authority: 'https://login.microsoftonline.com/touchsides.com',
    clientId: '26985b3f-5a43-4bc6-9d59-6b4ba317ce47',
    development: true,
  },
];

export default environments;
